const firebaseConfig = {
//   apiKey: "AIzaSyAY3v0jGvATxV5r4Tbv5YFVP2AwOWwZYrY",
//   authDomain: "ankmitinnovation.firebaseapp.com",
//   projectId: "ankmitinnovation",
//   storageBucket: "ankmitinnovation.appspot.com",
//   messagingSenderId: "355233850450",
//   appId: "1:355233850450:web:1e58a89bbd260b4ce96b8b",
//   measurementId: "G-9HKMFY5G0F",
    apiKey: "AIzaSyC1a-PalTUhXus4oqRwAd8hMY2NXTzmwsY",
    authDomain: "ankmitinnovation-582ea.firebaseapp.com",
    projectId: "ankmitinnovation-582ea",
    storageBucket: "ankmitinnovation-582ea.appspot.com",
    messagingSenderId: "137950171473",
    appId: "1:137950171473:web:5b9793374c70fe64428370",
    measurementId: "G-1H62FGV1Y3"
};

module.exports = firebaseConfig;
