import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

import Logo from "../../images/Logo1.png";
import media2 from "../../images/media2.jpg";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import WithoutVariant from "../../components/services/withoutVariant";
import WithVariant from "../../components/services/withVariant";
import { ServicesList } from '../../__data__/ServicesList';
// import { UserCartContext } from '../../context/user_cart_context/UserCartContext';
// import useNotification from "../../hooks/useNotification";

const Cart = () => {
  // const { NotificationComponent, triggerNotification } = useNotification("top-right");
  // const navigate = useNavigate();
  // const {userCartContextData, setUserCartContextData} = useContext(UserCartContext);
  const activeUserData = localStorage.getItem("activeUser");
  const activeUser = JSON.parse(activeUserData);
  const [activeUserState, setActiveUserState] = useState(activeUser);
  // const [cartTotalValue, setCartTotalValue] = useState(0);
  let cartTotalValue = 0;

  const convertToNumber = (stringNumber) => {
    return Number(stringNumber.replace(/,/g, ''));
  }

  const renderService = () => {
    if (activeUserState && activeUserState.cart.length > 0) {
      return activeUserState.cart.map((element) => {
        if (element === '1-1' || element === '1-2' || element === '1-3' || element === '2-1' || element === '2-2' || element === '2-3' || element === '7-1' || element === '7-2' || element === '7-3') {
          const eleArray = element?.split('');
          const variant = ServicesList[+eleArray[0] - 1].variants[+eleArray[2] - 1];
          cartTotalValue = cartTotalValue + convertToNumber(variant.price);
          return (
            <WithVariant key={element} variants={[variant]} title={ServicesList[+eleArray[0] - 1].title} shortDescription={ServicesList[+eleArray[0] - 1].shortDescription} id={element} />
          )
        } else {
          cartTotalValue = cartTotalValue + convertToNumber(ServicesList[2].price);
          return (
            <WithoutVariant key={element} title={ServicesList[2].title} shortDescription={ServicesList[2].shortDescription} id={ServicesList[2].id} />
          )
        }
      })
    }
  }

  const handleCheckout = () => {

    // console.log("cartTotalValue: ", cartTotalValue);
    const options = {
      key: "rzp_live_NNarF73Ff2NgTO", // Replace with your Razorpay key
      amount: cartTotalValue * 100, // Amount in the smallest currency unit (e.g., paise for INR)
      currency: "INR",
      name: "AnkmitInnovations",
      description: activeUserState.existingAuthenticationLists[0].fullName,
      handler: function (response) {
        // Handle the response from Razorpay here
        // console.log(response);
        // alert('Payment Successful');
        localStorage.setItem('activeUser', {
          isLogin: false,
          isLoading: false,
          isError: false,
          cart: [],
          existingAuthenticationLists: activeUserState.existingAuthenticationLists,
        })
      },
      prefill: {
        name: activeUserState.existingAuthenticationLists[0].fullName,
        email: activeUserState.existingAuthenticationLists[0].registrationEmail,
        contact: activeUserState.existingAuthenticationLists[0].mobile,
      },
      notes: {
        address: "Ankmit Innovations",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div id="top">
      <main>
        <Navbar Logo={Logo} />
        <header className="site-header d-flex flex-column justify-content-center align-items-center">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-5 col-12 mb-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Homepage</a>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      Cart
                    </li>
                  </ol>
                </nav>

                <h2 className="text-white">Cart</h2>
              </div>

              <div className="col-lg-5 col-12">
                <div className="topics-detail-block bg-white shadow-lg">
                  <img
                    src={media2}
                    className="topics-detail-block-image img-fluid"
                    alt="media"
                  />
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* <Notification type="success"message="test"/> */}

        {/* <button onClick={() => {
          triggerNotification({
            type: 'success',
            message: 'testing',
            duration: 30000,
            onClose: () => {
              console.log("close called");
            },
          })
        }}> Trigger success</button>

        {NotificationComponent}

        <section
          className="topics-detail-section section-padding"
          id="topics-detail"
        >
          <div className="container">
            <p>This is Services</p>

            <div id="root"></div>
          </div>
        </section> */}
        {/* {NotificationComponent} */}

        <section className="explore-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="mb-4">Cart</h2>
              </div>
            </div>
          </div>

          <div className="container">
            {activeUser.cart.length === 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="media-tab-pane"
                      role="tabpanel"
                      aria-labelledby="media-tab"
                      tabIndex="0"
                    >
                      <div className="row  justify-content-center">
                        <div className="col-lg-8 col-md-6 col-12 mb-4 mb-lg-0">
                          <div className="custom-block custom-flex-block bg-white shadow-lg">
                            <div className="d-flex justify-content-center">
                              <div className="justify-content-center">
                                <h5 className="mb-2 text-center">
                                  Cart is Empty
                                </h5>

                                <p className="mb-0">
                                  Your cart is currently empty. Please explore
                                  our service from{" "}
                                  <a href="/services"> here </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 ">
                  <div className="custom-block custom-flex-block bg-white shadow-lg">

                    <div className="row justify-content-center align-items-center">
                      {renderService()}
                    </div>
                    <div className="row justify-content-center mt-5">
                      <button type="submit" className="btn btn-primary btn-lg login-button col-6" onClick={handleCheckout}>Checkout</button>

                    </div>

                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>

      <Footer Logo={Logo} />
    </div>
  );
};

export default Cart;
