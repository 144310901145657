import React from "react";

import Logo from '../../images/Logo1.png';
import media2 from '../../images/media2.jpg';
// import media3 from '../../images/media3.jpg';
// import media4 from '../../images/media4.jpg';
import rearViewYoungCollegeStudent from '../../images/rear-view-young-college-student.jpg';

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const MediaDigital = () => {
    return (
        <div id="top">

            <main>

                <Navbar Logo={Logo} />

                <header className="site-header d-flex flex-column justify-content-center align-items-center">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">

                            <div className="col-lg-5 col-12 mb-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Homepage</a></li>

                                        <li className="breadcrumb-item active" aria-current="page">Marketing & Digital intermediate
                                            and Grading</li>
                                    </ol>
                                </nav>

                                <h2 className="text-white">Introduction to <br /> Marketing & Digital intermediate and Grading</h2>

                                <div className="d-flex align-items-center mt-5">
                                    <a href="#topics-detail" className="btn custom-btn custom-border-btn smoothscroll me-4">Read
                                        More</a>
                                </div>
                            </div>

                            <div className="col-lg-5 col-12">
                                <div className="topics-detail-block bg-white shadow-lg">
                                    <img src={media2} className="topics-detail-block-image img-fluid" alt="media"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </header>


                <section className="topics-detail-section section-padding" id="topics-detail">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-12 m-auto">
                                <h3 className="mb-4">Introduction to Marketing & Digital intermediate and Grading</h3>

                                <p><strong>Ankmit</strong> is focused on helping clients grow their business through our various
                                    digital marketing website services. This includes their website, search engine rankings,
                                    social media impact, digital advertising and online reputation management.</p>

                                <p>There is never a cookie-cutter approach to our services. We tailor campaigns to fit the needs
                                    of our clients and their customers, while also ensuring that we are maximizing the tools of
                                    whatever platform we are working on.</p>

                                <p>Because of this need for a varied and active approach to online Pink Dog marketing, we offer
                                    a wide variety of services that all are available to you.</p>

                                <p>Cultivating an online presence begins with having a stellar website with information easily
                                    accessible by potential customers, and we will help you build and maintain such a website.
                                </p>

                                <p>Once your website is completed, you also must ensure that it is pushed to customers.</p>

                                <blockquote>
                                    Ankmit organisation has wide range of experience in Marketing & Digital intermediate and
                                    Grading.
                                </blockquote>

                                <ul className="d-flex flex-column align-items-center">
                                    <li>Website Design</li>
                                    <li>Search Engine Optimization</li>
                                    <li>Content Creation</li>
                                    <li>Social Media</li>
                                    <li>Online Reputation</li>
                                    <li>Digital Advertising</li>
                                    <li>Digital Marketing Strategies</li>
                                    <li>Email Marketing</li>
                                    <li>Photography / Videography</li>
                                    <li>Day of Event Promotion</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="section-padding section-bg">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-5 col-12">
                                <img src={rearViewYoungCollegeStudent} className="newsletter-image img-fluid" alt="media" />
                            </div>

                            <div className="col-lg-5 col-12 subscribe-form-wrap d-flex justify-content-center align-items-center">
                                <form className="custom-form subscribe-form" action="#" method="post">
                                    <h4 className="mb-4 pb-2">Get Newsletter</h4>

                                    <input type="email" name="subscribe-email" id="subscribe-email" pattern="[^ @]*@[^ @]*"
                                        className="form-control" placeholder="Email Address" required="" />

                                        <div className="col-lg-12 col-12">
                                            <button type="submit" className="form-control">Subscribe</button>
                                        </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>

            </main>

            < Footer Logo={Logo} />

        </div>
    )
}

export default MediaDigital;