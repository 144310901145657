import React from "react";

const WithVariant = ({ AddToCart, id, title, shortDescription, variants }) => {
    // console.log("variants: ", variants);
    return (
        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 mt-5">
            <div className="custom-block custom-flex-block bg-white shadow-lg">
                {/* <a href="media"> */}
                <div className="d-flex">
                    <div>
                        <h5 className="mb-2">{title}</h5>

                        <p className="mb-0">
                            {shortDescription}
                        </p>
                    </div>
                </div>
                {/* <AddToCart id={id} /> */}

                {
                    variants && variants.length > 0 && variants?.map((data) => {
                        return (
                            <div className="custom-block custom-flex-block bg-white shadow-lg mt-2 mb-4" key={data.id}>
                                <div className="d-flex">
                                    <div>
                                        <h5 className="mb-2 text-center">Duration: {data.duration}</h5>

                                        <p className="mb-0">
                                            Price: {data.price}
                                        </p>
                                        <p className="mb-0">
                                            Time Duration: {data.timeDuration}
                                        </p>
                                        <p className="mb-0">
                                            Preparation Duration: {data.preDuration}
                                        </p>
                                    </div>
                                </div>
                               { AddToCart && <AddToCart id={data.id} />}
                            </div>
                        )
                    })
                }
                {/* <div className="custom-block custom-flex-block bg-white shadow-lg mt-2 mb-4">
                    <div className="d-flex">
                        <div>
                            <h5 className="mb-2">{title}</h5>

                            <p className="mb-0">
                                {shortDescription}
                            </p>
                        </div>
                    </div>
                    <AddToCart id={id} />
                </div>
                <div className="custom-block custom-flex-block bg-white shadow-lg mb-4">
                    <div className="d-flex">
                        <div>
                            <h5 className="mb-2">{title}</h5>

                            <p className="mb-0">
                                {shortDescription}
                            </p>
                        </div>
                    </div>
                    <AddToCart id={id} />
                </div>
                <div className="custom-block custom-flex-block bg-white shadow-lg">
                    <div className="d-flex">
                        <div>
                            <h5 className="mb-2">{title}</h5>

                            <p className="mb-0">
                                {shortDescription}
                            </p>
                        </div>
                    </div>
                    <AddToCart id={id} />
                </div> */}
            </div>
        </div>

    )
}

export default WithVariant;