import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

import Logo from "../../images/Logo1.png";
import media2 from "../../images/media2.jpg";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

// import { UserCartContext } from '../../context/user_cart_context/UserCartContext';

import useNotification from "../../hooks/useNotification";
import WithoutVariant from "../../components/services/withoutVariant";
import WithVariant from "../../components/services/withVariant";
import { ServicesList } from '../../__data__/ServicesList';


const Services = () => {
  const { NotificationComponent, triggerNotification } =
    useNotification("top-right");
  // const navigate = useNavigate();
  // const {userCartContextData, setUserCartContextData} = useContext(UserCartContext);
  const activeUserData = localStorage.getItem("activeUser");
  const activeUser = JSON.parse(activeUserData);
  const [activeUserState, setActiveUserState] = useState(activeUser);

  const AddToCart = ({ id }) => {
    const handleAddCart = ($event) => {
      $event.preventDefault();
      setActiveUserState({
        ...activeUserState,
        cart: [...activeUserState?.cart, id],
      });
      localStorage.setItem(
        "activeUser",
        JSON.stringify({
          ...activeUserState,
          cart: [...activeUserState?.cart, id],
        })
      );
      triggerNotification({
        type: "success",
        message: "Your services is added to the cart successfully",
        duration: 3000,
        onClose: () => {
          // console.log("close called");
        },
      });
    };

    const checkCart = activeUserState.cart.filter((element) => {
      return element == id;
    });

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {checkCart.length > 0 ? (
          <a href="/cart" className="btn custom-btn mt-2 mt-lg-3">
            Go to cart
          </a>
        ) : (
          <button
            className="btn custom-btn mt-2 mt-lg-3"
            onClick={($event) => {
              handleAddCart($event);
            }}
          >
            {" "}
            Add to cart{" "}
          </button>
        )}
      </div>
    );
  };


  return (
    <div id="top">
      {NotificationComponent}
      <main>
        <Navbar Logo={Logo} />
        <header className="site-header d-flex flex-column justify-content-center align-items-center">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-5 col-12 mb-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Homepage</a>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      Services
                    </li>
                  </ol>
                </nav>

                <h2 className="text-white">
                  Buy <br /> Services
                </h2>

                {/* <div className="d-flex align-items-center mt-5">
                  <a
                    href="#topics-detail"
                    className="btn custom-btn custom-border-btn smoothscroll me-4"
                  >
                    Read More
                  </a>
                </div> */}
              </div>

              <div className="col-lg-5 col-12">
                <div className="topics-detail-block bg-white shadow-lg">
                  <img
                    src={media2}
                    className="topics-detail-block-image img-fluid"
                    alt="media"
                  />
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="explore-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="mb-4">Buy Services</h2>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="media-tab-pane"
                    role="tabpanel"
                    aria-labelledby="media-tab"
                    tabIndex="0"
                  >
                    <div className="row">

                      <WithVariant variants={ServicesList[0].variants} title={ServicesList[0].title} shortDescription={ServicesList[0].shortDescription} id={ServicesList[0].id} AddToCart={AddToCart} />

                      <WithVariant variants={ServicesList[1].variants} title={ServicesList[1].title} shortDescription={ServicesList[1].shortDescription} id={ServicesList[1].id} AddToCart={AddToCart} />

                      <WithVariant variants={ServicesList[6].variants} title={ServicesList[6].title} shortDescription={ServicesList[6].shortDescription} id={ServicesList[6].id} AddToCart={AddToCart} />

                    </div>

                    <div className="row mt-5">

                      <WithoutVariant title={ServicesList[2].title} shortDescription={ServicesList[2].shortDescription} id={ServicesList[2].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[3].title} shortDescription={ServicesList[3].shortDescription} id={ServicesList[3].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[4].title} shortDescription={ServicesList[4].shortDescription} id={ServicesList[4].id} AddToCart={AddToCart} />

                    </div>

                    <div className="row mt-5">

                      <WithoutVariant title={ServicesList[5].title} shortDescription={ServicesList[5].shortDescription} id={ServicesList[5].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[7].title} shortDescription={ServicesList[7].shortDescription} id={ServicesList[7].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[8].title} shortDescription={ServicesList[8].shortDescription} id={ServicesList[8].id} AddToCart={AddToCart} />
                    </div>

                    <div className="row mt-5">

                      <WithoutVariant title={ServicesList[9].title} shortDescription={ServicesList[9].shortDescription} id={ServicesList[9].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[10].title} shortDescription={ServicesList[10].shortDescription} id={ServicesList[10].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[11].title} shortDescription={ServicesList[11].shortDescription} id={ServicesList[11].id} AddToCart={AddToCart} />
                    </div>

                    <div className="row mt-5">

                      <WithoutVariant title={ServicesList[11].title} shortDescription={ServicesList[11].shortDescription} id={ServicesList[11].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[12].title} shortDescription={ServicesList[12].shortDescription} id={ServicesList[12].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[13].title} shortDescription={ServicesList[13].shortDescription} id={ServicesList[13].id} AddToCart={AddToCart} />
                    </div>

                    <div className="row mt-5">

                      <WithoutVariant title={ServicesList[14].title} shortDescription={ServicesList[14].shortDescription} id={ServicesList[14].id} AddToCart={AddToCart} />

                      <WithoutVariant title={ServicesList[15].title} shortDescription={ServicesList[15].shortDescription} id={ServicesList[15].id} AddToCart={AddToCart} />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer Logo={Logo} />
      {NotificationComponent}
    </div>
  );
};

export default Services;
