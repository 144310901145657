import React, {useEffect} from "react";

import Logo from '../../images/Logo1.png';
import media2 from '../../images/media2.jpg';
import media3 from '../../images/media3.jpg';
import media4 from '../../images/media4.jpg';
import rearViewYoungCollegeStudent from '../../images/rear-view-young-college-student.jpg';

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Media = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <div id="top">

            <main>

                <Navbar Logo={Logo} />

                <header className="site-header d-flex flex-column justify-content-center align-items-center">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">

                            <div className="col-lg-5 col-12 mb-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Homepage</a></li>

                                        <li className="breadcrumb-item active" aria-current="page">Media</li>
                                    </ol>
                                </nav>

                                <h2 className="text-white">Introduction to <br /> Media</h2>

                                <div className="d-flex align-items-center mt-5">
                                    <a href="#topics-detail" className="btn custom-btn custom-border-btn smoothscroll me-4">Read More</a>
                                </div>
                            </div>

                            <div className="col-lg-5 col-12">
                                <div className="topics-detail-block bg-white shadow-lg">
                                    <img src={media2} className="topics-detail-block-image img-fluid" alt="media" />
                                </div>
                            </div>

                        </div>
                    </div>
                </header>


                <section className="topics-detail-section section-padding" id="topics-detail">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-12 m-auto">
                                <h3 className="mb-4">Introduction to Media</h3>

                                <p><strong>Ankmit</strong> engaged in Media & Marketing Consultancy, VFX & Animation work and intellectual Property Rights.</p>

                                <p>The Client, being desirous of engaging The Vendor Services (defined hereinafter) for Media & Marketing Consulting, Animation Work, Digital Intermediate & Grading, and Post Production Work and Software & Entertainment Services.</p>

                                <blockquote>
                                    <strong>Ankmit</strong> organisation has wide range of experience in Media services.
                                </blockquote>

                                <div className="row my-4">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <img src={media3} height="220" alt="media" className="topics-detail-block-image img-fluid" />
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12 mt-4 mt-lg-0 mt-md-0">
                                        <img src={media4} className="topics-detail-block-image img-fluid" alt="media" />
                                    </div>
                                </div>

                                <p>The Vendor is engaged in providing media & marketing services, including editing offline and online; VFX and animation; 2D to 3D conversions, CG, VR and augmented VR, restoration, digital intermediate and grading.</p>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="section-padding section-bg">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-5 col-12">
                                <img src={rearViewYoungCollegeStudent} className="newsletter-image img-fluid" alt="" />
                            </div>

                            <div className="col-lg-5 col-12 subscribe-form-wrap d-flex justify-content-center align-items-center">
                                <form className="custom-form subscribe-form" action="#" method="post">
                                    <h4 className="mb-4 pb-2">Get Newsletter</h4>

                                    <input type="email" name="subscribe-email" id="subscribe-email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Email Address" required="" />

                                    <div className="col-lg-12 col-12">
                                        <button type="submit" className="form-control">Subscribe</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>

            </main>

            < Footer Logo={Logo} />

        </div>
    )
}

export default Media;