import React, {useEffect} from "react";
import { Route, Routes } from "react-router-dom";

import "./js/jquery.min.js";
import "./js/bootstrap.bundle.min.js";
import "./js/jquery.sticky.js";
import "./js/click-scroll.js";
import "./js/custom.js";
import "./css/bootstrap.min.css";
import "./css/bootstrap-icons.css";
import "./css/templatemo-topic-listing.css";
import "./index.css";

import Logo from "./images/Logo1.png";
// import Img1 from './images/topics/undraw_Remote_design_team_re_urdx.png';
// import Img2 from './images/topics/undraw_Finance_re_gnv2.png';
import "./App.css";

// import Navbar from './components/navbar/Navbar';
// import Footer from './components/footer/Footer.jsx';
import Navbar from "./components/navbar/Navbar.jsx";

import Home from "./pages/Home/Home.jsx";
import ITDetails from "./pages/ITDetails/ITDetails";
import Media from "./pages/Media/Media";
import MediaDigital from "./pages/MediaDigital/MediaDigital";
import Authenticate from "./pages/Authentication/Authentication";
import Services from "./pages/Services/Services";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Home />
    </div >

    // <>
    //   <Navbar Logo={Logo} />
    //   {/* <div className="container"> */}
    //   <Routes>
    //     <Route path="/" element={<Home />} />
    //     <Route path="/itdetails" element={<ITDetails />} />
    //     <Route path="/media" element={<Media />} />
    //     <Route path="/mediadigital" element={<MediaDigital />} />
    //     <Route path="/authenticate" element={<Authenticate />} />
    //     <Route path="/services" element={<Services />} />
    //   </Routes>
    //   {/* </div> */}
    // </>
  );
}

export default App;
