import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  BrowserRouter,
} from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import firebaseConfig from "./config/firebase/firebase-config";

// import './js/jquery.min.js';
// import './js/bootstrap.bundle.min.js';
// import './js/jquery.sticky.js';
// import './js/click-scroll.js';
// import './js/custom.js';
// import './css/bootstrap.min.css';
// import './css/bootstrap-icons.css';
// import './css/templatemo-topic-listing.css';
// import './index.css';

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ITDetails from "./pages/ITDetails/ITDetails";
import Media from "./pages/Media/Media";
import MediaDigital from "./pages/MediaDigital/MediaDigital";
import Home from "./pages/Home/Home";
import Authenticate from "./pages/Authentication/Authentication";
import Services from "./pages/Services/Services";
import Cart from "./pages/Cart/Cart";

import UserCartContextProvider from "./context/user_cart_context/UserCartContext";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const activeUserData = JSON.parse(localStorage.getItem("activeUser"));

// console.log("activeUserData: ", activeUserData);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "itdetails",
    element: <ITDetails />,
  },
  {
    path: "media",
    element: <Media />,
  },
  {
    path: "mediadigital",
    element: <MediaDigital />,
  },
  {
    path: "authenticate",
    element: activeUserData?.isLogin ? (
      <Navigate to="/" replace />
    ) : (
      <Authenticate firebaseApp={app} firebaseDB={db} />
    ),
  },
  {
    path: "services",
    element: !activeUserData?.isLogin ? (
      <Navigate to="/" replace />
    ) : (
      // <Services />
      <Services />
    ),
  },
  {
    path: "cart",
    element: !activeUserData?.isLogin ? (
      <Navigate to="/" replace />
    ) : (
      // <Services />
      <Cart />
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserCartContextProvider>
      <RouterProvider router={router} />
    </UserCartContextProvider>
  </React.StrictMode>

  // <React.StrictMode>
  //   <BrowserRouter>
  //     <App />
  //   </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
